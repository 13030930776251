/**
 * 加密/解密字符串
 * creator by WangJunCheng on 2022/02/28
 * last update by WangJunCheng on 2022/02/28
 */

/**
 * 加密字符串
 * @param code 字符串 - 必填
 * @returns string
 */
export const encryption = (code: string) => {
    let c = String.fromCharCode(code.charCodeAt(0) + code.length)
    for (let i = 1; i < code.length; i++) {
        c += String.fromCharCode(code.charCodeAt(i) + code.charCodeAt(i - 1))
    }
    return encodeURIComponent(c)
}

/**
 * 解密字符串
 * @param code 字符串 - 必填
 * @returns string
 */
export const decrypt = (code: string | null) => {
    let c = ''
    if (code) {
        code = decodeURIComponent(code)
        c = String.fromCharCode(code.charCodeAt(0) - code.length)
        for (let i = 1; i < code.length; i++) {
            c += String.fromCharCode(code.charCodeAt(i) - c.charCodeAt(i - 1))
        }
    }
    return c
}
