<script lang="ts" setup>
import { onMounted } from 'vue'
import loginStore from '@/store/modules/loginStore'
import { storeToRefs } from 'pinia'
const lStore = loginStore()
const { loginYzmTime, resetPswYzmTime } = storeToRefs(lStore)

onMounted(() => {
    if (loginYzmTime.value > 0) {
        const timeId = setInterval(() => {
            lStore.offLoginYzmTime()
            if (loginYzmTime.value === 0) clearInterval(timeId)
        }, 1000)
        const timeId2 = setInterval(() => {
            lStore.offResetPswYzmTime()
            if (resetPswYzmTime.value === 0) clearInterval(timeId2)
        }, 1000)
    }
})
</script>
<template>
    <div>
        <router-view />
    </div>
</template>
<style>
html,
body,
#app {
    width: 100vw;
    height: 100vh;
    overflow-y: hidden;
    /* min-width: 1680px; */
    margin: 0;
}
* {
    box-sizing: border-box;
}
</style>
