import { defineStore } from 'pinia'
import { decrypt, encryption } from '../tools/compileString'
import { menuRouterConfig } from '@/config'

const userStore = defineStore('userStore', {
    state: () => ({
        // token
        token: decrypt(sessionStorage.getItem('token') || ''),
        // 用户信息
        userInfo: JSON.parse(sessionStorage.getItem('userInfo') || '{}'),
        // 权限列表
        permissionList: JSON.parse(
            sessionStorage.getItem('permissionList') || '[]',
        ),
    }),
    getters: {
        /**
         * 所有目录菜单列表（非tree）
         * @param state
         * @returns
         */
        routeList: state => {
            return state.permissionList
                .filter((item: any) => {
                    return item.type === 1 || item.type === 2
                })
                .map((item: any) => {
                    return Object.assign({}, item, menuRouterConfig[item.route])
                })
        },
        /**
         * 导航菜单（tree）
         * @param state
         * @returns
         */
        menuList: state => {
            const res = state.permissionList.map((item: any) => {
                return Object.assign({}, item, menuRouterConfig[item.route])
            })
            return getMenu(res)
        },
        /**
         * 能被设为tab的列表
         * @param state
         * @returns
         */
        canbeTabList: state => {
            return state.permissionList
                .filter((item: any) => {
                    return (
                        (item.type === 1 || item.type === 2) &&
                        item.route !== '/portal'
                    )
                })
                .map((item: any) => {
                    return Object.assign({}, item, menuRouterConfig[item.route])
                })
        },
    },
    actions: {
        /**
         * 设置 token
         * @param token
         */
        setToken(token: string) {
            this.token = token
            sessionStorage.setItem('token', encryption(token))
        },
        /**
         * 设置用户信息
         */
        setUserInfo(userInfo: any) {
            this.userInfo = userInfo
            sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
        },
        /**
         * 设置权限
         */
        setPermissionList(permissionList: any[]) {
            this.permissionList = permissionList
            sessionStorage.setItem(
                'permissionList',
                JSON.stringify(permissionList),
            )
        },
    },
})
export default userStore

const getMenu = (permissionList: any) => {
    const res = permissionList.filter((item: any) => {
        return item.type === 1 || item.type === 2
    })
    res.forEach((item: any) => {
        const res1 = res.filter((item1: any) => {
            return item1.gid === item.parentGid
        })
        if (res1.length) {
            if (res1[0].children) {
                res1[0].children.push(item)
                res1[0].children = res1[0].children.sort(sort('orderIndex'))
            } else {
                res1[0].children = [item]
            }
        }
    })
    return res
        .filter((item: any) => {
            return item.parentGid === 0
        })
        .sort(sort('orderIndex'))
}

const sort = (key: string) => {
    return (a: any, b: any) => {
        if (a[key] < b[key]) return -1
        if (a[key] > b[key]) return 1
        return 0
    }
}

export const getPermission = (
    type: number,
    nameOrGid: string | number,
    isGid?: boolean,
) => {
    if (isGid) {
        return userStore()
            .permissionList.filter((item: any) => {
                return item.parentGid === nameOrGid
            })
            .map((item: any) => {
                return item.name
            })
    } else {
        const thisGid = userStore().permissionList.filter((item: any) => {
            return item.type === type && item.name === nameOrGid
        })[0].gid
        return userStore()
            .permissionList.filter((item: any) => {
                return item.parentGid === thisGid
            })
            .map((item: any) => {
                return item.name
            })
    }
}
