import { createRouter, createWebHistory } from 'vue-router'
import routes from '@/route/routes'
import store from '@/store/index'
import tabBarStore from '@/store/modules/tabBarStore'
import userStore from '@/store/modules/userStore'
import { storeToRefs } from 'pinia'

const tabStore = tabBarStore(store)
const uStore = userStore()
const { canbeTabList, token } = storeToRefs(uStore)

const route = createRouter({
    history: createWebHistory(),
    routes,
})

const whiteList = ['/login', '/test', '/monitorViewGet']

route.beforeEach((to, from, next) => {
    // next()
    console.log(to.path)
    if (!whiteList.includes(to.path) && !token.value) next('/login')
    else {
        if (
            canbeTabList.value.some((item: any) => {
                if (item.menuPosition === 1)
                    return to.path.indexOf(item.route) > -1
                return item.route === to.path
            })
        ) {
            if (to.query.name) {
                tabStore.addTab(to.path, decodeURI(<string>to.query.name))
            } else {
                tabStore.addTab(to.path)
            }
        } else {
            tabStore.setCurrentTab({})
        }
        next()
    }
})

export default route
