// import initWasm from '@/wasm'
import { createApp } from 'vue'
import App from '@/App.vue'
import store from '@/store'
import route from '@/route'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

import '@/assets/styles/tailwind.scss'

// initWasm().then(() => {
createApp(App)
    .use(store)
    .use(route)
    .use(ElementPlus, {
        locale: zhCn,
    })
    .mount('#app')
// })
