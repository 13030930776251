import { defineStore, storeToRefs } from 'pinia'
import router from '@/route/index'
import userStore from '@/store/modules/userStore'
import store from '@/store/index'

const uStore = userStore(store)
const { routeList } = storeToRefs(uStore)

const tabBarStore = defineStore('tabBarStore', {
    state: () => ({
        // tabList
        tabList: JSON.parse(sessionStorage.getItem('tabList') || '[]'),
        // 当前tab
        currentTab: JSON.parse(sessionStorage.getItem('currentTab') || '{}'),
    }),
    actions: {
        /**
         * 增加tab
         * @param
         */
        addTab(path: any, tabName?: string) {

            if (
                !this.tabList.some((item: any) => {
                    return item.route === path
                })
            ) {
                const temp =  routeList.value.filter((item: any) => {
                    if (item.menuPosition === 1) return path.indexOf(item.route) > -1
                    return item.route === path
                })[0]
                this.tabList.push( Object.assign({}, temp, {route: path, name: tabName || temp.name}))
                sessionStorage.setItem('tabList', JSON.stringify(this.tabList))

                this.setCurrentTab(Object.assign({}, temp, {route: path, name: tabName || temp.name}))
            } else {
              const temp =  this.tabList.filter((item: any) => {
                return item.route === path
              })[0]
              this.setCurrentTab(temp)
            }
        },
        /**
         * 关闭tab
         * @param
         */
        closeTab(path: string) {
            let closeIndex = -1
            this.tabList.forEach((item: any, index: number) => {
                if (item.route === path) closeIndex = index
            })
            this.tabList.splice(closeIndex, 1)
            sessionStorage.setItem('tabList', JSON.stringify(this.tabList))
            if (this.tabList.length === 0) {
                router.push('/portal')
            } else {
                if (path === this.currentTab.route) {
                    router.push(this.tabList[this.tabList.length - 1].route)
                }
            }
        },
        /**
         * 关闭所有
         * @param
         */
        closeAll() {
            this.tabList = []
            sessionStorage.setItem('tabList', JSON.stringify(this.tabList))
        },
        /**
         * 关闭其他
         * @param
         */
        closeOther() {
          if (this.currentTab.route) {
            this.tabList = [this.currentTab]
            sessionStorage.setItem('tabList', JSON.stringify(this.tabList))
          } else {
            this.closeAll()
          }
        },
        /**
         * 设置currentTab
         * @param
         */
        setCurrentTab(tab: any) {
            this.currentTab = tab
            sessionStorage.setItem('currentTab', JSON.stringify(tab))
        },
    },
})
export default tabBarStore
