import testRoutes from '@/route/modules/testRoutes'

const routes = [
    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/index.vue'),
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/index.vue'),
        redirect: '/portal',
        children: [
            {
                path: '/personCenter',
                name: 'personCenter',
                component: () => import('@/views/personCenter/index.vue'),
            },
            {
                path: '/contact',
                name: 'contact',
                component: () => import('@/views/contact/index.vue'),
            },
            {
                path: '/portal',
                name: 'portal',
                component: () => import('@/views/portal/index.vue'),
            },
            {
                path: '/moduleEdit',
                name: 'moduleEdit',
                component: () => import('@/views/module/moduleEdit/index.vue'),
            },
            {
                path: '/chiken',
                name: 'chiken',
                component: () => import('@/views/module/chiken/index.vue'),
            },
            {
                path: '/chikenDetail/:gid',
                name: 'chikenDetail',
                component: () =>
                    import('@/views/module/chiken/chikenDetail.vue'),
            },
            {
                path: '/duck',
                name: 'duck',
                component: () => import('@/views/module/duck/index.vue'),
            },
            {
                path: '/duckDetail/:gid',
                name: 'duckDetail',
                component: () => import('@/views/module/duck/duckDetail.vue'),
            },
            {
                path: '/pig',
                name: 'pig',
                component: () => import('@/views/module/pig/index.vue'),
            },
            {
                path: '/pigDetail/:gid',
                name: 'pigDetail',
                component: () => import('@/views/module/pig/pigDetail.vue'),
            },
            {
                path: '/fish',
                name: 'fish',
                component: () => import('@/views/module/fish/index.vue'),
            },
            {
                path: '/fishDetail/:gid',
                name: 'fishDetail',
                component: () => import('@/views/module/fish/fishDetail.vue'),
            },
            {
                path: '/mushroom',
                name: 'mushroom',
                component: () => import('@/views/module/mushroom/index.vue'),
                // component: () => import('@/views/noOpen.vue'),
            },
            {
                path: '/mushroomDetail/:gid',
                name: 'mushroomDetail',
                component: () =>
                    import('@/views/module/mushroom/mushroomDetail.vue'),
            },
            {
                path: '/room',
                name: 'room',
                component: () => import('@/views/module/room/index.vue'),
            },
            {
                path: '/roomDetail/:gid',
                name: 'roomDetail',
                component: () => import('@/views/module/room/roomDetail.vue'),
            },
            {
                path: '/tree',
                name: 'tree',
                // component: () => import('@/views/module/tree/index.vue'),
                component: () => import('@/views/develop.vue'),
            },
            {
                path: '/treeDetail/:gid',
                name: 'treeDetail',
                component: () => import('@/views/module/tree/treeDetail.vue'),
            },
            {
                path: '/tent',
                name: 'tent',
                component: () => import('@/views/module/tent/index.vue'),
            },
            {
                path: '/tentDetail/:gid',
                name: 'tentDetail',
                component: () => import('@/views/module/tent/tentDetail.vue'),
            },
            {
                path: '/waterLoop',
                name: 'waterLoop',
                component: () => import('@/views/module/waterLoop/index.vue'),
            },
            {
                path: '/waterLoopDetail/:gid',
                name: 'waterLoopDetail',
                component: () =>
                    import('@/views/module/waterLoop/waterLoopDetail.vue'),
            },
            {
                path: '/userManage',
                name: 'userManage',
                component: () => import('@/views/system/userManage/index.vue'),
            },
            {
                path: '/roleManage',
                name: 'roleManage',
                component: () => import('@/views/system/roleManage/index.vue'),
            },
            {
                path: '/nongceList',
                name: 'nongceList',
                component: () => import('@/views/nongce/list/index.vue'),
            },
            {
                path: '/nongkenList',
                name: 'nongkenList',
                component: () => import('@/views/nongken/list/index.vue'),
            },
            {
                path: '/nonggengList',
                name: 'nonggengList',
                component: () => import('@/views/nonggeng/list/index.vue'),
            },
            {
                path: '/nongzhong',
                name: 'nongzhong',
                component: () => import('@/views/develop.vue'),
            },
            {
                path: '/dikuai',
                name: 'dikuai',
                component: () => import('@/views/nongguan/dikuai/index.vue'),
            },
            {
                path: '/chanpin',
                name: 'chanpin',
                component: () => import('@/views/nongguan/chanpin/index.vue'),
            },
            {
                path: '/renwumuban',
                name: 'renwumuban',
                component: () =>
                    import('@/views/nongguan/renwumuban/index.vue'),
            },
            {
                path: '/products',
                name: 'products',
                component: () => import('@/views/nonggong/products/index.vue'),
            },
            {
                path: '/orders',
                name: 'orders',
                component: () => import('@/views/nonggong/orders/index.vue'),
            },
            {
                path: '/shops',
                name: 'shops',
                component: () => import('@/views/nonggong/shops/index.vue'),
            },
            {
                path: '/zixun',
                name: 'zixun',
                component: () => import('@/views/zixun/list/index.vue'),
            },
            {
                path: '/developing',
                name: 'developing',
                component: () => import('@/views/develop.vue'),
            },
            {
                path: '/chuidiao',
                name: 'chuidiao',
                component: () => import('@/views/develop.vue'),
            },
            {
                path: '/daotian',
                name: 'daotian',
                component: () => import('@/views/newDaotian/index.vue'),
            },
            {
                path: '/yangzhi',
                name: 'yangzhi',
                component: () => import('@/views/newYangzhi/index.vue'),
            },
            {
                path: '/youchai',
                name: 'youchai',
                component: () => import('@/views/develop.vue'),
            },
        ],
    },
    {
        path: '/monitorView',
        name: 'monitorView',
        component: () => import('@/views/monitorView/index.vue'),
    },
    {
        path: '/monitorViewGet',
        name: 'monitorViewGet',
        component: () => import('@/views/monitorView/monitorViewGet.vue'),
    },
    ...testRoutes,
]

export default routes

export const hasRoute = (route: string, routeList?: any[]) => {
    let res = false
    routeList = routeList || routes
    routeList.forEach((item: any, index) => {
        if (item.path === route) res = true
        else if (item.children) {
            let temp = hasRoute(route, item.children)
            res = temp ? temp : res
        }
    })
    return res
}
