import { defineStore } from 'pinia'

const loginStore = defineStore('loginStore', {
    state: () => ({
        loginYzmTime: parseInt(sessionStorage.getItem('loginYzmTime') || '0'),
        resetPswYzmTime: parseInt(
            sessionStorage.getItem('resetPswYzmTime') || '0',
        ),
    }),
    actions: {
        setLoginYzmTime(value: number) {
            this.loginYzmTime = value
            sessionStorage.setItem('loginYzmTime', this.loginYzmTime + '')
        },
        offLoginYzmTime() {
            this.loginYzmTime = this.loginYzmTime - 1
            sessionStorage.setItem('loginYzmTime', this.loginYzmTime + '')
        },
        setResetPswYzmTime(value: number) {
            this.resetPswYzmTime = value
            sessionStorage.setItem('resetPswYzmTime', this.resetPswYzmTime + '')
        },
        offResetPswYzmTime() {
            this.resetPswYzmTime = this.resetPswYzmTime - 1
            sessionStorage.setItem('resetPswYzmTime', this.resetPswYzmTime + '')
        },
    },
})

export default loginStore
