import moment from 'moment'
// 首页菜单路由
export const menuRouterConfig: { [propsName: string]: any } = {
    '/module': {
        route: '/module',
        shortName: '模块',
    },
    '/moduleEdit': {
        route: '/moduleEdit',
    },
    '/personCenter': {
        route: '/personCenter',
        shortName: '账户',
    },
    '/contact': {
        route: '/contact',
        shortName: '客服',
    },
    '/system': {
        route: '/system',
        shortName: '管理',
    },
    '/nongce': {
        route: '/nongce',
        shortName: '农测',
    },
    '/nongken': {
        route: '/nongken',
        shortName: '农垦',
    },
    '/nonggeng': {
        route: '/nonggeng',
        shortName: '农耕',
    },
    '/nongzhong': {
        route: '/nongzhong',
        shortName: '农种',
    },
    '/nongguan': {
        route: '/nongguan',
        shortName: '农管',
    },
    '/nonggong': {
        route: '/nonggong',
        shortName: '农供',
    },
    '/njzx': {
        route: '/njzx',
        shortName: '咨询',
    },
}

// 加载组件
export const loadingOptions = {
    lock: true,
    background: 'rgba(1, 1, 1, 0)',
    spinner: 'test'
}

// 模块头部分类
export const moduleTypes = [
    {
        id: 'chiken',
        name: '智慧鸡舍',
        icon: '/image/module/chikenDefault.png',
        activeIcon: '/image/module/chikenActive.png',
    },
    {
        id: 'duck',
        name: '智慧鸭舍',
        icon: '/image/module/duckDefault.png',
        activeIcon: '/image/module/duckActive.png',
    },
    {
        id: 'pig',
        name: '智慧猪舍',
        icon: '/image/module/pigDefault.png',
        activeIcon: '/image/module/pigActive.png',
    },
    {
        id: 'fish',
        name: '智慧鱼塘',
        icon: '/image/module/fishDefault.png',
        activeIcon: '/image/module/fishActive.png',
    },
    {
        id: 'mushroom',
        name: '智慧菌菇',
        icon: '/image/module/mushroomDefault.png',
        activeIcon: '/image/module/mushroomActive.png',
    },
    {
        id: 'room',
        name: '智慧大棚',
        icon: '/image/module/roomDefault.png',
        activeIcon: '/image/module/roomActive.png',
    },
    {
        id: 'tent',
        name: '智慧露营',
        icon: '/image/module/tentDefault.png',
        activeIcon: '/image/module/tentActive.png',
    },
    {
        id: 'tree',
        name: '智慧果园',
        icon: '/image/module/treeDefault.png',
        activeIcon: '/image/module/treeActive.png',
    },
    {
        id: 'waterLoop',
        name: '智慧水循环',
        icon: '/image/module/waterLoopDefault.png',
        activeIcon: '/image/module/waterLoopActive.png',
    },
    {
        id: 'camera',
        name: '监控摄像',
        icon: '/image/module/cameraDefault.png',
        activeIcon: '/image/module/cameraActive.png',
    },
]

export const colorList = [
    '#00ACA2',
    '#02B084',
    '#02B847',
    '#04BB33',
    '#06C113',
    '#00ACA2',
    '#02B084',
    '#02B847',
    '#04BB33',
    '#06C113',
]

export const barChartOptions = {
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow',
        },
    },
    grid: {
        left: 0,
        top: 20,
        bottom: 0,
        right: 10,
        containLabel: true,
    },
    xAxis: {
        type: 'category',
        axisTick: {
            show: false,
        },
    },
    yAxis: {
        type: 'value',
        axisLine: {
            show: true,
        },
        splitLine: {
            show: false,
        },
        splitNumber: 2,
    },
    series: {
        color: colorList,
        type: 'bar',
        barWidth: 16,
        colorBy: 'data',
        itemStyle: {
            borderRadius: [999, 999, 0, 0],
        },
        label: {
            show: true,
            position: 'top',
            color: 'inherit',
            distance: 1,
        },
    },
}

export const rowbarChartOptions = {
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow',
        },
    },
    grid: {
        left: 20,
        top: 20,
        bottom: 40,
        right: 20,
    },
    legend: {
        bottom: 0,
        icon: 'roundRect',
        left: 0,
        pageIconSize: [10, 10],
        pageIconColor: '#02B084',
        type: 'scroll',
    },
    xAxis: {
        type: 'value',
        axisLine: {
            show: true,
        },
        splitLine: {
            show: false,
        },
        splitNumber: 2,
    },
    yAxis: {
        type: 'category',
        axisTick: {
            show: false,
        },
        axisLabel: {
            show: false,
        },
    },
    series: {
        color: colorList,
        colorBy: 'data',
        type: 'bar',
        barWidth: 16,
        itemStyle: {
            borderRadius: [0, 999, 999, 0],
        },
        label: {
            show: true,
            position: 'right',
            color: 'inherit',
        },
    },
}

export const rowbarNolegendOptions = {
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow',
        },
    },
    grid: {
        left: 0,
        top: 10,
        bottom: 0,
        right: 40,
        containLabel: true,
    },
    xAxis: {
        type: 'value',
        splitLine: {
            show: false,
        },
        splitNumber: 2,
    },
    yAxis: {
        type: 'category',
        axisTick: {
            show: false,
        },
    },
    series: {
        color: colorList,
        colorBy: 'data',
        type: 'bar',
        barWidth: 16,
        itemStyle: {
            borderRadius: [0, 999, 999, 0],
        },
        label: {
            show: true,
            position: 'right',
            color: 'inherit',
        },
    },
}
export const lineChartOptions = () => {
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
        },
        grid: {
            left: 0,
            top: 20,
            bottom: 20,
            right: 10,
            containLabel: true,
        },
        legend: {
            bottom: 0,
            icon: 'roundRect',
            left: 0,
            pageIconSize: [10, 10],
            pageIconColor: '#02B084',
            type: 'scroll',
        },
        xAxis: {
            type: 'category',
            // axisLine: {
            //     show: false,
            // },
            axisTick: {
                show: false,
            },
            axisLabel: {
                interval: 0,
            },
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: true,
            },
            splitLine: {
                show: false,
            },
            splitNumber: 2,
        },
        series: {
            type: 'line',
            barWidth: 16,
            itemStyle: {
                borderRadius: [999, 999, 0, 0],
            },
            label: {
                show: true,
                position: 'right',
                color: 'inherit',
            },
            symbol: 'circle',
        },
    }
}
export const lineChartNoLegendOptions = () => {
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
        },
        grid: {
            left: 40,
            top: 20,
            bottom: 20,
            right: 10,
        },
        xAxis: {
            type: 'category',
            axisTick: {
                show: false,
            },
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: true,
            },
            splitLine: {
                show: false,
            },
            splitNumber: 2,
        },
        series: {
            type: 'line',
            label: {
                show: true,
                position: 'top',
                color: 'inherit',
            },
            symbol: 'circle',
        },
    }
}

export const gaugeChartOptions = {
    series: {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        color: colorList,
        colorBy: 'data',
        radius: '100%',
        clockwise: false,
        pointer: {
            show: false,
        },
        progress: {
            show: true,
            roundCap: true,
            width: 5,
        },
        axisLine: {
            lineStyle: {
                width: 5,
            },
        },
        splitLine: {
            show: false,
        },
        axisTick: {
            show: false,
        },
        axisLabel: {
            show: false,
        },
        detail: {
            color: 'auto',
            formatter: '{value}%',
            fontSize: '12px',
        },
    },
}

export const scatterChartOptions = () => {
    return {
        grid: {
            top: 10,
            left: 0,
            right: 10,
            bottom: 10,
            containLabel: true,
        },
        xAxis: {
            scale: true,
        },
        yAxis: {
            scale: true,
        },
        effectScatter: {
            type: 'effectScatter',
            symbolSize: 8,
            color: colorList,
            colorBy: 'data',
        },
        scatter: {
            type: 'scatter',
            color: colorList,
            colorBy: 'data',
            symbolSize: 2,
        },
    }
}

export const weekList = [
    { value: 1, name: '周一' },
    { value: 2, name: '周二' },
    { value: 3, name: '周三' },
    { value: 4, name: '周四' },
    { value: 5, name: '周五' },
    { value: 6, name: '周六' },
    { value: 7, name: '周日' },
]

export const feedCycleList = [
    { value: 1, name: '每天' },
    { value: 2, name: '每周' },
]

export const feedCycleObj: { [propsName: string]: string } = {
    1: '每天',
    2: '每周',
}

export const matterList = [
    { value: 1, name: '净水' },
    { value: 2, name: '有机灰水' },
    { value: 3, name: '营养液' },
]

export const matterObj: { [propsName: string]: string } = {
    1: '净水',
    2: '有机灰水',
    3: '营养液',
}

export const formatOriginTime = (start: number, end: number) => {
    const res = []
    for (let i = start; i < end; i++) {
        res.push({
            value: (i + '').padStart(2, '0') + ':00',
            name: (i + '').padStart(2, '0') + ':00',
        })
        res.push({
            value: (i + '').padStart(2, '0') + ':30',
            name: (i + '').padStart(2, '0') + ':30',
        })
    }
    res.push({
        value: (end + '').padStart(2, '0') + ':00',
        name: (end + '').padStart(2, '0') + ':00',
    })
    return res
}

export const dayAdd = (date: string, days: number) => {
    return moment(new Date(date).getTime() + days * 24 * 60 * 60 * 1000).format(
        'YYYY-MM-DD',
    )
}

export const dayMinus = (date: string, days: number) => {
    return moment(new Date(date).getTime() - days * 24 * 60 * 60 * 1000).format(
        'YYYY-MM-DD',
    )
}

function base64ToFile(urlData: any, filename = 'file') {
    let arr = urlData.split(',')
    let mime = arr[0].match(/:(.*?);/)[1]
    let suffix = mime.split('/')[1]
    let bstr = atob(arr[1])
    let n = bstr.length
    let u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, {
        type: mime,
    })
    // return new Blob([u8arr], { type: mime })
}

export const downloadFileByBase64 = (base64: string, name: string) => {
    const blob = base64ToFile(base64)
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.setAttribute('href', url)
    a.setAttribute('download', name)
    a.setAttribute('target', '_blank')
    const clickEvent = document.createEvent('MouseEvents')
    clickEvent.initEvent('click', true, true)
    a.dispatchEvent(clickEvent)
}
